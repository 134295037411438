import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../components/GQLCodeSnippet/GQLCodeSnippet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The TransportAddress scalar is a Layer 4 (Transport) address.
It consists of a Layer 4 protocol (TCP/UDP), a server address
(IP address/FQDN) and a port (1-65535) in form of: `}<inlineCode parentName="p">{`proto://serveraddress:port`}</inlineCode>{`.
i.e: `}<inlineCode parentName="p">{`tcp://192.168.100.50:8080`}</inlineCode>{`, `}<inlineCode parentName="p">{`udp://logcollector.example.intranet:10210`}</inlineCode>{`.`}</p>
    <GQLCodeSnippet mdxType="GQLCodeSnippet">
      <GQLLine mdxType="GQLLine"><GQLKeyword mdxType="GQLKeyword">scalar</GQLKeyword> TransportAddress</GQLLine>
    </GQLCodeSnippet>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      